.footerArea {
  background: #0e1d28;
  padding-top: 120px;
  position: relative;
  background-image: url("/assets/img/footer.png");
  color: var(--light-text);
  min-height: 500px;

  @media (max-width: 992px) {
    padding-top: var(--spacing-xl);
  }
  .footerBackground {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
  }
  .footerRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: var(--spacing-sm);
    border-bottom: 1px solid var(--dark-background);

    .column {
      max-width: 192px;
      &.logoColumn {
        @media (max-width: 992px) {
          max-width: 400px;
          margin-right: auto;
          margin-left: auto;
          padding: 0 var(--spacing-sm);
        }
        @media (max-width: 768px) {
          padding: 0 var(--spacing-md);
        }
      }
      .logoDescription {
        opacity: 0.8;
      }
      @media (max-width: 992px) {
        max-width: 100%;
        margin-bottom: var(--spacing-xl);
      }
    }

    .socialContacts {
      display: flex;
      align-items: center;

      font-size: 16px;
      line-height: 150%;
      color: #ffffff;
      opacity: 0.8;

      span {
        margin-right: var(--spacing-xs);
      }
    }
  }
  .bottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 35px 0;
    color: white;

    @media (max-width: 992px) {
      justify-content: space-evenly;
      text-align: center;
    }

    p {
      margin: 0;
    }

    .tcLinks {
      a {
        color: white;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
      @media (max-width: 992px) {
        order: 1;
        margin-bottom: var(--spacing-sm);
      }
    }

    .socialLinks {
      @media (max-width: 992px) {
        order: 2;
        margin-bottom: var(--spacing-sm);
      }
    }

    .copyright {
      @media (max-width: 992px) {
        order: 3;
      }
    }
  }
}
